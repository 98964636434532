import LoginForm from './LoginForm';
import { Redirect } from '@mna/react';

export default ({ state, actions }) =>
  state.user && state.user.id ? (
    <Redirect to="/dashboard" />
  ) : (
    <div className="article-width">
      <LoginForm {...{ actions }} />
    </div>
  );
