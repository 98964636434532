import { Component } from '@mna/react';
import getFormData from '@mna/dom/getFormData';

export default class LoginForm extends Component {
  state = {
    formData: {
      name: '',
      password: '',
    },
    invalidFields: {},
    resultMessage: '',
  };

  onSubmit(e) {
    const { actions } = this.props;
    e.preventDefault();

    const formData = getFormData(this.el);
    const invalid = {};

    Object.keys(formData).forEach(key => {
      if (!formData[key]) invalid[key] = true;
    });

    this.setState({ formData, invalidFields: invalid, resultMessage: '' });

    if (!Object.keys(invalid).length) {
      actions
        .login({ data: formData })
        .then(result => {
          if (!result) {
            this.setState({ resultMessage: 'Failed' });
            return;
          }
          // Redirects on success
          //this.setState({ resultMessage: 'Success!' })
        })
        .catch(e => {
          console.log(e);
        });
    }
  }

  render() {
    const { formData, invalidFields, resultMessage } = this.state;

    return (
      <div className="site-container">
        <main className="page d_board">
          <div className="container">
            <div className="dashboard home">
              <h2>Login page</h2>
            </div>
          </div>
        </main>
      </div>
    );
  }
}
